import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["classNames", "hits", "itemComponent", "sendEvent", "emptyComponent"];
import { cx } from "../lib/index.js";

// Should be imported from a shared package in the future

export function createHitsComponent(_ref) {
  var createElement = _ref.createElement;
  return function Hits(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      hits = userProps.hits,
      ItemComponent = userProps.itemComponent,
      sendEvent = userProps.sendEvent,
      EmptyComponent = userProps.emptyComponent,
      props = _objectWithoutProperties(userProps, _excluded);
    if (hits.length === 0 && EmptyComponent) {
      return createElement(EmptyComponent, {
        className: cx('ais-Hits', classNames.root, cx('ais-Hits--empty', classNames.emptyRoot), props.className)
      });
    }
    return createElement("div", _extends({}, props, {
      className: cx('ais-Hits', classNames.root, hits.length === 0 && cx('ais-Hits--empty', classNames.emptyRoot), props.className)
    }), createElement("ol", {
      className: cx('ais-Hits-list', classNames.list)
    }, hits.map(function (hit, index) {
      return createElement(ItemComponent, {
        key: hit.objectID,
        hit: hit,
        index: index,
        className: cx('ais-Hits-item', classNames.item),
        onClick: function onClick() {
          sendEvent('click:internal', hit, 'Hit Clicked');
        },
        onAuxClick: function onAuxClick() {
          sendEvent('click:internal', hit, 'Hit Clicked');
        }
      });
    })));
  };
}